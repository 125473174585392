$(document).ready(function(){
	$(window).load(function(){



		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		// INITIATIONS
		AOS.init();

		// CUSTOM FUNCTIONS
    set_active_state();
    objLightcase();
    slick_banner();
    slick_sliders();
    scrollbars();
    call_scrollify();
    calculator();
    map_accordion();
    lazyload();
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
    caretMenu();

		$('.loader-overlay').fadeOut(200);

    $('input[type=number]').on('keydown', function(evt) {
    var key = evt.charCode || evt.keyCode || 0;

    return (key == 8 ||
            key == 9 ||
            key == 46 ||
            key == 110 ||
            key == 190 ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105));
    });

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
// if( Modernizr.mq('(min-width: 1200px)') ) {
// }
// else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
// }
// else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
// }
// else{
// }

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }

  //Variables on page load
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");

  //Initialize carousel
  $myCarousel.carousel();
  //Animate captions in first slide on page load
  doAnimations($firstAnimatingElems);
  //Pause carousel
  $myCarousel.carousel('pause');
  //Other slides to be animated on carousel slide event
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
  var $subheader  = $('header.sub-header').outerHeight();
	$('.wrapper-holder').css({
		'min-height': $pageHeight,
		//'margin-top': $navHeight,
		'padding-bottom': $footerHeight
	});
	$('.main-layout').css({
		'min-height': $pageHeight - ( $navHeight + $footerHeight ),
	});
  $('.inner-page-layout').css({
    'padding-top': $subheader
  });
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	var sitelink = $('.usd').data('usdacct');
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	  location = sitelink + '/thank-you/';
	}, false );
}

function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function slick_banner() {

	$('#banner-slider').on('init', function(e, slick) {
    var $animatingElements = $('div.slick-slide[data-slick-index="0"]').find('[data-animation]');
    doAnimations($animatingElements);
  });
  $('#banner-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
		var $animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
		doAnimations($animatingElements);
	});

	$('#banner-slider').slick({
	    infinite: true,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	    arrows: true,
	    dots: true,
	    autoplay: false,
	    cssEase: 'linear',
      fade: true,
	    responsive: [{
	            breakpoint: 1024,
	            settings: {
	                arrows: false,
	            }
	        },
	        {
	            breakpoint: 768,
	            settings: {
	                arrows: false,
	            }
	        },
	        {
	            breakpoint: 480,
	            settings: {
	                arrows: false,
	            }
	        }
	    ],
	});

}

function doAnimations(elements) {
  var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  elements.each(function() {
    var $this = $(this);
    var $animationDelay = $this.data('delay');
    var $animationType = 'animated ' + $this.data('animation');
    $this.css({
        'animation-delay': $animationDelay,
        '-webkit-animation-delay': $animationDelay
    });
    $this.addClass($animationType).one(animationEndEvents, function() {
        $this.removeClass($animationType);
    });
  });
 }


function call_scrollify() {
  $.scrollify({
    section : ".section-scroll",
    interstitialSection : "",
    easing: "easeOutExpo",
    scrollSpeed: 1100,
    offset : 0,
    scrollbars: true,
    standardScrollElements: "",
    setHeights: true,
    overflowScroll: true,
    updateHash: false,
    touchScroll:true,
    before:function() {},
    after:function() {},
    afterResize:function() {},
    afterRender:function() {}
  });
}


function slick_sliders() {
  $('.product-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      cssEase: 'linear',
      fade: true,
      autoplay: true,
      responsive: [{
              breakpoint: 1024,
              settings: {
                  arrows: false,
              }
          },
          {
              breakpoint: 768,
              settings: {
                  arrows: false,
              }
          },
          {
              breakpoint: 480,
              settings: {
                  arrows: false,
              }
          }
      ],
  });

  $('#gallery-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      cssEase: 'linear',
      fade: true,
      autoplay: true,
  });
}

function scrollbars() {
  $(".left-scroll").mCustomScrollbar({
    axis:"y",
    theme:"dark",
    updateOnContentResize: true
  });
  $(".right-scroll").mCustomScrollbar({
    axis:"y",
    theme:"dark",
    updateOnContentResize: true
  });
  $(".filter-result ").mCustomScrollbar({
    axis:"y",
    theme:"dark",
    updateOnContentResize: true
  });
}

function calculator () {
  $('.modal-calculator').on('click' , '#caculate-btn' , function(){
    var width = $('#meters').val(),
        height = $('#height').val(),
        area = $('#area').val(),
        size = $('#tile-size option:selected').val(),
        percent = $('#tile-size option:selected').data('perdata'),
        fields = width , height , area , size , percent;

    if (area == '') {
      var precentresult = (width * height * size) * percent + (width * height * size) ;
      var finalresult = Math.ceil(precentresult);
      if (finalresult == 0) {
          $('#caculate-result').html('Total: 0 number of tiles.(appx.)');
      } else {
          $('#caculate-result').html('Total: ' + finalresult + ' number of tiles.(appx.)');
      }
    } else {
      var arearesult = (area * size) * percent ;
      var percent = (area * size) + arearesult;
      var finalresult = Math.ceil(percent);
      if (finalresult == 0 ) {
          $('#caculate-result').html('Total: 0 number of tiles.(appx.)');
      } else {
          $('#caculate-result').html('Total: ' + finalresult + ' number of tiles.(appx.)');
      }
    }
      // if (fields == '') {
      //     $('#caculate-result').html('Total: 0 number of tiles.(appx.)');
      // }else {
          
      // }
  });
}
function lazyload() {
    $('.list-products').lazy({
        effect: "fadeIn",
        effectTime: 300,
        threshold: 0
    });
}

function map_accordion() {
  $('body').on('click', '.slide-open', function() {
      var target = $(this).data('target');
      console.log(target);
      if ($(this).hasClass('active')) {
          $('#' + target).slideUp();
          $(this).removeClass('active');
      } else {
          $('.slide-target').slideUp();
          $('#' + target).slideDown();
          $('.slide-open').removeClass('active');
          $(this).addClass('active');
      }
  });
}

function set_active_state() {
  var $current_page = $('#current-page').val();
  if($current_page) {
    $('#menu-main-menu-1').find('.' + $current_page ).addClass('custom-active');
  }

  var $current_single = $('#current-single').val();
  if( $current_single ) {
    $('#menu-main-menu-1').find('.' + $current_single ).addClass('custom-active');
  }
}


function caretMenu(){
  $('.desktop-menu .nav .menu li').each( function(){
    var menu = $(this);
    if( menu.find('.sub-menu').length > 0 ) {
      menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
    }
  });
}